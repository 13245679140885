<template>
    <div class="preview_content"
         v-if="getCampaignAdd.script_ids && getCampaignAdd.script_ids.length > 0">
        <div class="content_inner">
            <p>This preview can be seen in a new window</p>
            <button class="btn---cta btn-blue btn-round"
                    @click.prevent="$store.dispatch('previewWidget', getCampaignAdd.script_ids)">
                <span>Preview</span>
            </button>

        </div>

    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default ({
  computed: {
    ...mapGetters([
      'getCampaigns',
      'getCampaignAdd'
    ])
  }
})
</script>
