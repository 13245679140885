<template>
  <transition name="show">

    <!--<div id="referralModal" class="modal fade normal_modal referralModal" role="dialog" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">

                    <button type="button" class="close" data-dismiss="modal">
                        <img src="/img/cancel.png" alt="">
                    </button>

                    <div class="referral_box" style="padding-bottom: 10px;">
                        <img src="/img/pattern.svg" alt="">
                        <p>
                            Add
                            <span>{{getCallToActionSelection.requiredReferral - getProfile.referral_level}}</span>
                            more referral to unlock this feature
                        </p>
                    </div>
                    <div class="shorten_form text-center" style="padding-bottom:30px;">
                        <p class="link shorten_sec">
                            <a v-clipboard:copy="referralLink()"
                               v-clipboard:success="onCopy"
                               v-clipboard:error="onError"
                               class="share_link" href="javascript:;"> {{referralLink()}}</a>
                            <input type="button" v-clipboard:copy="referralLink()"
                                   v-clipboard:success="onCopy"
                                   v-clipboard:error="onError" value="Copy to Clipboard" class="btn&#45;&#45;cta">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>-->

    <div class="brand_layout">
      <div v-if="getGuidedTourOverlay" class="guided_overlay_black" id="guided_overlay_black"></div>
      <div class="page_inner">

        <guided-tour v-if="getGuidedTour.step7"></guided-tour>
        <guided-tour v-if="getGuidedTour.step9"></guided-tour>
        <div :style="computeLeftInnerWidthLayout($route.fullPath)"
             :class="{'activeTourPositionFix' : getGuidedTour.step7 ||  getGuidedTour.step9}" class="sec_left">
          <div class="left_inner">
            <transition name="slide">
              <div v-if="$route.name !== 'link'" class="cta_main_view w-100">
                <div class="view_inner d-flex flex-column">
                  <div class="view_top d-flex align-items-center">
                    <h4 v-if="$route.name !== 'link'" class="title">{{
                        computeCampaignSectionTitle($route.name).title
                      }}</h4>
                    <router-link class="cross_icon ml-auto" :to="{name: 'campaigns'}">
                      <i class="fal fa-times"></i>
                    </router-link>
                  </div>
                  <div class="flex campaign_section_navbar h-100">
                    <div style="box-shadow: 0 30px 40px 0 rgb(0 0 0 / 5%); z-index: 10"
                         class="bg-white position-relative">
                      <ul class="text-center campaign_section_navbar">
                        <li class="p-1" :style="getCampaignSelectedOptionStyle('create')">
                          <router-link :to="{name: 'create'}">
                            <img width="60px" class="p-2" src="/assets/img/campaing_logos/Brand.png"/>
                          </router-link>
                        </li>
                        <li @click.prevent="validateCampaign('campaign_type')" v-if="getCampaignAdd.brand_id"
                            class="px-1 py-3"
                            :style="getCampaignSelectedOptionStyle('campaign_type')">
                          <i v-if="getCampaignSectionsValidations.campaignName"
                             class="fal mr-auto fa-exclamation-triangle campaign_alert campaign_form_alert custom_tooltip tag_tooltip">
                            <div class="tool_tip_box">
                              <div class="inner__box">
                                <span>Incomplete/Invalid Details</span>
                              </div>
                            </div>
                          </i>
                          <img width="35px" src="/assets/img/campaing_logos/Campaign Type.png"/>
                        </li>

                        <li @click.prevent="validateCampaign('call_to_action_type')"
                            v-if="getCampaignAdd.campaign_type.cta" class="px-1 py-3"
                            :style="getCampaignSelectedOptionStyle('call_to_action_type')">
                          <i
                            v-if="getCampaignSectionsValidations.image || getCampaignSectionsValidations.integration || getCampaignSectionsValidations.agreement"
                            class="fal mr-auto fa-exclamation-triangle campaign_alert campaign_form_alert custom_tooltip tag_tooltip">
                            <div class="tool_tip_box">
                              <div class="inner__box">
                                <span>Incomplete/Invalid Details</span>
                              </div>
                            </div>
                          </i>
                          <img width="40px" src="/assets/img/campaing_logos/CTA Type.png"/>
                        </li>
                        <li v-if="getCampaignAdd.campaign_type.cta" class="px-1 py-3"
                            @click.prevent="validateCampaign('message')"
                            :style="getCampaignSelectedOptionStyle('message')">
                          <i v-if="getCampaignSectionsValidations.customMessage"
                             class="fal mr-auto fa-exclamation-triangle campaign_alert campaign_form_alert custom_tooltip tag_tooltip">
                            <div class="tool_tip_box">
                              <div class="inner__box">
                                <span>Incomplete/Invalid Details</span>
                              </div>
                            </div>
                          </i>
                          <img width="35px" src="/assets/img/campaing_logos/CTA message.png"/>
                        </li>
                        <li @click.prevent="validateCampaign('customize')" v-if="getCampaignAdd.campaign_type.cta"
                            class="px-1 py-3"
                            :style="getCampaignSelectedOptionStyle('customize')">
                          <img width="35px" src="/assets/img/campaing_logos/CTA customization New.png"/>
                        </li>
                        <li v-if="getCampaignAdd.campaign_type.cta" class="px-1 py-3"
                            @click.prevent="validateCampaign('triggers')"
                            :style="getCampaignSelectedOptionStyle('triggers')">
                          <img width="35px" src="/assets/img/campaing_logos/When to post.png"/>
                        </li>
                        <li v-if="getCampaignAdd.campaign_type.retargeting" class="px-1 py-3"
                            @click.prevent="validateCampaign('retargeting')"
                            :style="getCampaignSelectedOptionStyle('retargeting')">
                          <img width="35px" src="/assets/img/campaing_logos/Retargeting.png"/>
                        </li>
                        <li v-if="getCampaignAdd.campaign_type.third_party" class="px-1 py-3"
                            @click.prevent="validateCampaign('third_party_integration')"
                            :style="getCampaignSelectedOptionStyle('third_party_integration')">
                          <img width="35px" src="/assets/img/campaing_logos/Custom Widget.png"/>
                        </li>
                      </ul>
                    </div>
                    <router-view></router-view>
                  </div>
                </div>
              </div>
              <router-view></router-view>
            </transition>
          </div>
        </div>
        <div :style="computeRightInnerPaddingLayout($route.fullPath)" class="sec_right">
          <div class="right_inner">
            <preview></preview>
          </div>
        </div>
      </div>

    </div>


  </transition>
</template>
<style lang="less">
@import "../../../assets/less/common";
</style>
<script>

import Preview from './preview/Preview.vue'

import {mapGetters} from 'vuex'

import GuidedTour from '@/views/guided-tour/GuidedTour.vue'
import EventBus from "@/state/event-bus";
import {campaignTypes, linkTypes} from "@/state/modules/mutation-types";

export default ({
  data () {
    return {
      campaignSectionTitleList: [
        {
          route_name: 'create',
          title: 'Select Your Brand'
        },
        {
          route_name: 'campaign_type',
          title: 'Select Campaign Type'
        },
        {
          route_name: 'call_to_action_type',
          title: 'Select CTA Type'
        },
        {
          route_name: 'message',
          title: 'Call-to-Action Message'
        },
        {
          route_name: 'message',
          title: 'Call-to-Action Message'
        },
        {
          route_name: 'customize',
          title: 'Call-to-Action Customization'
        },
        {
          route_name: 'triggers',
          title: 'When it should appear ?'
        },
        {
          route_name: 'retargeting',
          title: 'Add Your Retargeting Code'
        },
        {
          route_name: 'third_party_integration',
          title: 'Add Your Custom Widgets'
        },
      ]
    }
  },
  components: {
    Preview,
    GuidedTour
  },
  created () {
    this.$store.commit(campaignTypes.SET_CAMPAIGN_SECTION_VALIDATIONS, {
      campaignName: false,
      image: false,
      integration: false,
      agreement: false,
      customMessage: false
    })
    this.$store.commit(linkTypes.SET_LINK_PREVIEW_ERROR, '')
    this.$store.commit(campaignTypes.RESET_CAMPAIGN_VALIDATIONS)
    if (!this.getProfile.email) {
      this.getUserProfile()
    }
  },
  computed: {
    ...mapGetters(['getGuidedTour', 'getGuidedTourOverlay', 'getProfile', 'getCampaignAdd', 'getCampaignSectionsValidations'])
  },
  methods: {
    /**
     * getting the page title by route
     * @param route
     * @returns {{route_name: string, title: string} | {route_name: string, title: string} | {route_name: string, title: string} | {route_name: string, title: string} | {route_name: string, title: string}}
     */
    computeCampaignSectionTitle (route) {
      if(route === 'triggers') {
        let obj = this.campaignSectionTitleList.find(item => item.route_name == route)
        obj.title = this.isBridgeCTACampaign() ? 'When it should redirect ?' : 'When it should appear ?'
        return obj
      }
      return this.campaignSectionTitleList.find(item => item.route_name == route)
    },
    /**
     * styling the section against route
     * @param route
     * @returns {{backgroundColor: (string)}}
     */
    getCampaignSelectedOptionStyle (route) {
      return {
        backgroundColor: this.$route.name === route ? '#f2f6f7' : ''
      }
    },
    /**
     * navigating the campaing section
     * @param type
     */
    validateCampaign (type) {
      this.$router.push({name: type})
    }
  }
})

</script>
