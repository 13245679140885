<template functional>
    <div class="row">
        <div class="col-sm-10 banner no-float">
            <div class="logo_area">

            </div>

            <div class="menu">
                <ul>
                    <li class="li_even">

                    </li>
                    <li>

                    </li>
                    <li>

                    </li>
                    <li>

                    </li>

                </ul>
            </div>

        </div>
        <div class="col-sm-10 nopad slider_section no-float">
            <div class="row">
                <div class="col-sm-8 nopad-l left_slider">
                    <div class="box_shape">
                        <div class="big_lines">
                            <div class="big_line_pad">
                                <div class="big__line"></div>
                            </div>
                            <div class="big_line_pad">
                                <div class="big__line medium"></div>
                            </div>
                            <div class="big_line_pad">
                                <div class="big__line low"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-4 nopad-r right_slider">
                    <div class="box_shape">

                    </div>
                </div>
            </div>



        </div>
        <div class="col-sm-10 nopad posts_section no-float">
            <div class="row">
                <div class="col-sm-4 nopad-l">
                    <div class="box_shape">

                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="box_shape">

                    </div>
                </div>

                <div class="col-sm-4 nopad-r">
                    <div class="box_shape">

                    </div>
                </div>
            </div>



        </div>
        <div class="col-sm-10 nopad posts_section no-float">
            <div class="row">
                <div class="col-sm-4 nopad-l">
                    <div class="box_shape">

                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="box_shape">

                    </div>
                </div>

                <div class="col-sm-4 nopad-r">
                    <div class="box_shape">

                    </div>
                </div>
            </div>



        </div>
        <div class="col-sm-10 nopad posts_section no-float">
            <div class="row">
                <div class="col-sm-4 nopad-l">
                    <div class="box_shape">

                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="box_shape">

                    </div>
                </div>

                <div class="col-sm-4 nopad-r">
                    <div class="box_shape">

                    </div>
                </div>
            </div>



        </div>
    </div>

</template>
<script>
export default ({})
</script>
