<template>

  <transition name="slide">

    <div id="generic_site_preview" class="generic_site_preview container-fluid">

      <div class="os_head">
        <div class="col-sm-12 nopad-l ">
          <div class="top_light_grey no-margin-r">
            <div class="os_circle red">
            </div>

            <div class="os_circle yellow">

            </div>

            <div class="os_circle green">
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>

      <div class="screen_wrapper">

        <div class="col-sm-12 nopad-l over_gray screen_wrapper_inner ">

          <div v-if="getLinks.previewURL && !['retargeting','integration','no_cta'].includes(getCampaignAdd.type)"
               class="iframe_over_gray">
            <iframe style="opacity:0.6;" :src="getLinks.previewURL" frameborder="0" height="1200"
                    allowTransparency="true"
                    allow="encrypted-media"
                    width="100%"></iframe>
          </div>

          <div v-else-if="getLinks.previewErrorMessage === 'notAllowed' && isFallbackCtaEnabled() && !['retargeting','integration','no_cta'].includes(getCampaignAdd.type)">
            <div class="summary_page">
              <div class="bg"></div>
              <div class="_scroll">
                <div class="box_inner">
                  <img width="10%" :src="getSaveLink.custom_favicon_url">
                  <h2 class="mt-3">{{ getSaveLink.seo.title }}</h2>
                  <div class="img_block text-center mt-5">
                    <div class="img my-auto position-relative align_center">
                      <img width="80%" height="400px" class="box_shadow m-auto" :src="getSaveLink.seo.image" alt="">
                    </div>
                  </div>
                    <p class="cta_message mt-5">{{ getSaveLink.seo.description }}</p>
                  <div class="btn_style">
                    <a :href="getSaveLink.url" class="btn " target="_blank">Go to Shortened URL <i class="fas ml-1 fa-external-link"></i></a>
                  </div>
                  <div class="clearfix"></div>
                </div>

              </div>

            </div>
          </div>

          <div v-else class="banner_section">
            <widget-preview></widget-preview>
            <preview-screen></preview-screen>
          </div>

          <div class="clearfix"></div>

          <!-- ----------------------CUSTOM SCRIPT  TEMPLATE -------------- -->

          <!--<template v-if="getCallToActionSelection.savingDetails.type == 'integration' && getCallToActionSelection.savingDetails.script_ids.length > 0">-->

          <!--<template  v-for="script_id in getCallToActionSelection.savingDetails.script_ids" >-->
          <!--&lt;!&ndash;// js&ndash;&gt;-->

          <!--&lt;!&ndash;<iframe :id="'js_iframe_'+script_id"  @load="frameload(this,'js_iframe_'+script_id)"  :src="getCustomCodeUrl(script_id,'js')" frameborder="0" ></iframe>&ndash;&gt;-->
          <!--<div class="custom_script_container" :class="getCustomCodeClass(script_id)" >-->
          <!--<button class="close_btn" >-->
          <!--<img src="/img/cancel.png" alt="">-->
          <!--</button>-->
          <!--&lt;!&ndash;// html&ndash;&gt;-->
          <!--<iframe :id="'html_iframe_'+script_id"  @load="frameload(this,'html_iframe_'+script_id)"  :src="getCustomCodeUrl(script_id,'all')" frameborder="0" ></iframe>-->

          <!--&lt;!&ndash;<div id="test_iframe_html" ></div>&ndash;&gt;-->

          <!--</div>-->
          <!--</template>-->
          <!--</template>-->

          <!-- ----------------------NORMAL BOX TEMPLATE -------------- -->

          <div id="#shoutout_box_single" class="shoutout_box_single clearfix"
               :class="{'left_arrow': getCampaignAdd.position === 'left', 'right_arrow': getCampaignAdd.position === 'right'}"
               v-if="getCampaignAdd.cta_type === 'social' && getCampaignAdd.type !== 'retargeting'
                                         && getCampaignAdd.type !== 'integration' && getCampaignAdd.type !== 'no_cta'">

            <transition name="bounce">

              <div class="shoutout_box_inner clearfix" v-show="toggleStatus"

                   :style="{'border-top': '3px solid ' +  borderColor }">
                <button class="close_btn"
                        :style="{'background-color': getCampaignAdd.background_color, 'color': getCampaignAdd.text_color}">
                  <i class="fas fa-times"></i>
                </button>
                <div class="box_content"
                     :style="{'background-color': getCampaignAdd.background_color}">
                  <div class="inner_content cta_prev_form clearfix">
                    <div class="content_box">
                      <template v-if="getSaveLink.has_custom_message">
                        <h2 :style="{'color': getCampaignAdd.text_color}">{{ getSaveLink.message_headline }}</h2>
                        <p class="cta_message"
                           :style="{'color': getCampaignAdd.text_color}">{{ getSaveLink.message }}</p>
                      </template>
                      <template v-else>
                        <h2 :style="{'color': getCampaignAdd.text_color}">{{ getCampaignAdd.message_headline }}</h2>
                        <p class="cta_message"
                           :style="{'color': getCampaignAdd.text_color}">{{ getCampaignAdd.message }}</p>
                      </template>
                    </div>
                    <div class="box_btn"
                         v-if="getCampaignAdd.type !== 'form'">
                      <a
                        :style="{'background-color': getCampaignAdd.button_background_color, 'color': getCampaignAdd.button_text_color}"
                        v-if="getCampaignAdd.type === 'button'"
                        :href="getCampaignAdd.button_url" class="btn"
                        target="_blank">{{ getCampaignAdd.button_text }}</a>
                      <!--:style="{'color': getCallToActionSelection.savingDetails.button_text_color}"-->
                      <a class="link_btn button_click"
                         :style="{'color': getCampaignAdd.link_color}"
                         v-if="getCampaignAdd.type === 'link'"
                         :href="getCampaignAdd.button_url"
                         target="_blank">{{ getCampaignAdd.button_text }}</a>
                    </div>
                    <!-- input form for the email capture -->
                    <div v-else class=" clear basic_form">

                      <div v-if="getCampaignAdd.email_field_name"
                           class="col-md-12 input_field"><input type="text"
                                                                placeholder="Enter your name">
                      </div>
                      <div class="col-md-12 input_field"><input type="text"
                                                                placeholder="Enter your email address">
                      </div>

                      <div class="clearfix"></div>

                      <div class="checkbox_option"
                           v-if="getCampaignAdd.agreement.length > 0">
                        <div class="small top_side checkbox"
                             v-for="agreement in getCampaignAdd.agreement">

                          <input :id="agreement.id" type="checkbox">
                          <label :for="agreement.id" v-html="agreement.text"></label>
                        </div>
                      </div>

                      <div class="box_btn">
                        <a class="btn"
                           :style="{'background-color': getCampaignAdd.button_background_color, 'color': getCampaignAdd.button_text_color}"
                           href="javascript:;">{{ getCampaignAdd.button_text }}</a>
                      </div>
                    </div>

                  </div>
                  <div class="clearfix"></div>
                  <p :style="{'color': getCampaignAdd.text_color}"
                     v-if="computePowerBy() && !isWhiteLabelSetup()" class="right powered_by bg-transparent">Powered by <a
                    href="https://replug.io/?utm_source=powered_by_replug" target="_blank"><strong :style="{'color': getCampaignAdd.text_color}">{{ getSiteDetails.agency_name }}</strong></a></p>

                </div>
                <div :style="{'border-top': '10px solid' + getCampaignAdd.background_color}"
                     class="arrow_bottom"></div>
              </div>
            </transition>

            <div class="box_footer relative"
                 :class="{'no_border' : (getCampaignAdd.circle === false ||  getCampaignAdd.circle ==='false') }">
              <!--:style="{'background': getCallToActionSelection.savingDetails.button_background_color}"-->
              <div class="image_box " @click="popupShow">

                <img v-if="brandAvatar()"
                     :src="brandAvatar()" alt="">
                <img v-else src="/assets/img/profile-default.png" alt="">

              </div>
              <p class="name">{{ getSelectedBrandObj(getCampaignAdd.brand_id).name }}</p>
            </div>

          </div>

          <!-- ----------------------NORMAL BOX NEW TEMPLATE -------------- -->

          <div id="#single_box_bottomSmall" class="single_box_bottomSmall clearfix"
               :class="{'left_side': getCampaignAdd.position === 'left', 'right_side': getCampaignAdd.position === 'right'}"
               v-if="getCampaignAdd.cta_type === 'social_modern' && getCampaignAdd.type!=='retargeting' && getCampaignAdd.type!=='integration' && getCampaignAdd.type !== 'no_cta'">

            <div class="shoutout_box_inner clearfix"
                 :style="{'border-top': '3px solid ' +  borderColor,'background-color': getCampaignAdd.background_color }">

              <div class="top_heading" v-if="getCampaigns.social_cta_stats">
                <!--                <p class="name">-->
                <!--                  {{ getBrandById(getCampaignAdd.brand_id).name }}</p>-->
                <button class="close_btn"
                        :style="{'background-color': getCampaignAdd.background_color, 'color': getCampaignAdd.text_color}">
                  <i class="fas fa-times"></i>
                </button>
              </div>
              <div class="box_content">

                <div class="inner_content cta_prev_form clearfix">
                  <div class="content_box text-center">
                    <template v-if="getSaveLink.has_custom_message">
                      <h2 :style="{'color': getCampaignAdd.text_color}">{{ getSaveLink.message_headline }}</h2>
                      <p class="cta_message"
                         :style="{'text_center':getCampaignAdd.type!=='form', 'color': getCampaignAdd.text_color}">
                        {{ getSaveLink.message }}</p>
                    </template>
                    <template v-else>
                      <h2 :style="{'color': getCampaignAdd.text_color}">{{ getCampaignAdd.message_headline }}</h2>
                      <p class="cta_message" style="white-space: normal"
                         :style="{'text_center':getCampaignAdd.type!=='form', 'color': getCampaignAdd.text_color}">
                        {{ getCampaignAdd.message }}</p>
                    </template>
                  </div>


                  <div class="btn_block" v-if="getCampaigns.social_cta_stats">
                    <div v-if="getCampaignAdd.type === 'form'"
                         class=" inner fields_three fields_three_full">
                      <input v-if="getCampaignAdd.email_field_name"
                             class="item_input" type="text" placeholder="Enter your name">
                      <input class="item_input" type="email"
                             placeholder="Enter your email address">
                      <button
                        :style="{'background-color': getCampaignAdd.button_background_color, 'color': getCampaignAdd.button_text_color}"
                        class="item_input btn-sm btn blue">
                        {{ getCampaignAdd.button_text }}
                      </button>
                      <div class="checkbox_option"
                           v-if="getCampaignAdd.agreement.length > 0">
                        <div class="small top_side checkbox"
                             v-for="agreement in getCampaignAdd.agreement">
                          <input :id="agreement.id" type="checkbox">
                          <label :for="agreement.id" v-html="agreement.text"></label>
                        </div>
                      </div>
                    </div>

                    <div v-if="getCampaignAdd.type === 'link'"
                         class=" link_block">

                      <a class="link_btn"
                         :style="{'color': getCampaignAdd.link_color}"
                         v-if="getCampaignAdd.type === 'link'"
                         :href="getCampaignAdd.button_url"
                         target="_blank">{{ getCampaignAdd.button_text }}</a>
                    </div>


                    <a
                      :style="{'background-color': getCampaignAdd.button_background_color, 'color': getCampaignAdd.button_text_color}"
                      v-if="getCampaignAdd.type === 'button'"
                      :href="getCampaignAdd.button_url" class="btn btn-sm"
                      target="_blank">{{ getCampaignAdd.button_text }}</a>


                  </div>

                  <p :style="{'color': getCampaignAdd.text_color}"
                     v-if="computePowerBy() && !isWhiteLabelSetup()" class=" powered_by">Powered by <a
                    href="https://replug.io/?utm_source=powered_by_replug" target="_blank"><strong :style="{'color': getCampaignAdd.text_color}">{{ getSiteDetails.agency_name }}</strong></a>
                  </p>

                </div>
              </div>

              <!--  ================== Curve svg ================== -->

              <svg class="curve_bottom"
                   xmlns:dc="http://purl.org/dc/elements/1.1/"
                   xmlns:cc="http://creativecommons.org/ns#"
                   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                   xmlns:svg="http://www.w3.org/2000/svg"
                   xmlns="http://www.w3.org/2000/svg"
                   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                   width="32"
                   height="32"
                   viewBox="0 0 8.4666667 8.4666672"
                   version="1.1"
                   id="svg8"
                   inkscape:version="0.92.1 unknown"
                   sodipodi:docname="curve.svg">
                <defs
                  id="defs2"/>
                <sodipodi:namedview
                  id="base"
                  pagecolor="#ffffff"
                  bordercolor="#666666"
                  borderopacity="1.0"
                  inkscape:pageopacity="0.0"
                  inkscape:pageshadow="2"
                  inkscape:zoom="11.485928"
                  inkscape:cx="27.881532"
                  inkscape:cy="9.7170888"
                  inkscape:document-units="px"
                  inkscape:current-layer="layer1"
                  showgrid="false"
                  units="px"
                  inkscape:window-width="1625"
                  inkscape:window-height="1026"
                  inkscape:window-x="55"
                  inkscape:window-y="24"
                  inkscape:window-maximized="1"
                  inkscape:snap-bbox="true"/>
                <metadata
                  id="metadata5">
                  <rdf:RDF>
                    <cc:Work
                      rdf:about="">
                      <dc:format>image/svg+xml</dc:format>
                      <dc:type
                        rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
                      <dc:title></dc:title>
                    </cc:Work>
                  </rdf:RDF>
                </metadata>
                <g
                  inkscape:label="Layer 1"
                  inkscape:groupmode="layer"
                  id="layer1"
                  transform="translate(0,-288.53331)">
                  <path
                    :style="{'fill': getCampaignAdd.background_color, 'stroke':  getCampaignAdd.background_color}"
                    style="stroke-width:0;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                    d="m 8.3101494,289.06885 c 0,0 -0.102041,5.19908 0.113099,7.87589 l -8.25232661,-0.11369 c 7.78985661,-1.85763 7.39021061,-7.84542 7.39021061,-7.84542 z"
                    id="path4498-3"
                    inkscape:connector-curvature="0"
                    sodipodi:nodetypes="ccccc"/>
                </g>
              </svg>
              <!--  ================== Curve svg  End ================== -->

              <!--<img class="curve_bottom" src="/img/curve.svg" alt="">-->
            </div>

            <div class="box_footer"
                 :class="{'no_border' : (getCampaignAdd.circle ==false ||  getCampaignAdd.circle =='false')}">
              <!--:style="{'background': getCampaignAdd.button_background_color}"-->
              <div class="image_box ">
                <div class="notification_circle">1</div>
                <img v-if="brandAvatar()"
                     :src="brandAvatar()" alt="">
                <img v-else src="/assets/img/profile-default.png" alt="">
              </div>

            </div>
          </div>

          <!-- ----------------------WIDE SCREEN TEMPLATE -------------- -->

          <div class="shoutout_box_wide  left_arrow clearfix" v-if="getCampaignAdd.cta_type == 'banner' && getCampaignAdd.type!='retargeting'
                    && getCampaignAdd.type!='integration' && getCampaignAdd.type != 'no_cta'">

            <!--<transition name="wideSlide">-->
            <div class="shoutout_box_inner clearfix" :style="{'border-top': '3px solid ' +  borderColor }">

              <button class="close_btn"
                      :style="{'background-color': getCampaignAdd.background_color, 'color': getCampaignAdd.text_color}">
                <i class="fas fa-times"></i>
              </button>

              <div class="box_content"
                   :style="{'background-color': getCampaignAdd.background_color}">
                <div class="box_left">
                  <div class="image_box">
                    <img :src="brandAvatar()"/>
                  </div>
                </div>

                <div class="box_right">

                  <div class="inner_content_inline clear">
                    <div class="inner_content_inline_inner ">

                      <template v-if="getSaveLink.has_custom_message">
                        <h2 class=""
                            :style="{'color': getCampaignAdd.text_color}">{{ getSaveLink.message_headline }}
                        </h2>
                      </template>
                      <template v-else>
                        <h2 v-if="getCampaignAdd.message_headline"
                            class=""
                            :style="{'color': getCampaignAdd.text_color}">{{ getCampaignAdd.message_headline }}
                        </h2>
                      </template>

                      <div class="box_btn"
                           v-if="getCampaignAdd.type !='form'">
                        <a
                          :style="{'background-color': getCampaignAdd.button_background_color, 'color': getCampaignAdd.button_text_color}"
                          v-if="getCampaignAdd.type == 'button'"
                          :href="getCampaignAdd.button_url" class="btn"
                          target="_blank">{{ getCampaignAdd.button_text }}</a>

                        <a :style="{'color': getCampaignAdd.link_color}"
                           v-if="getCampaignAdd.type == 'link'"
                           :href="getCampaignAdd.button_url"
                           class="btn_link"
                           target="_blank">{{ getCampaignAdd.button_text }}</a>
                      </div>
                      <div v-else class=" basic_form">
                        <div v-if="getCampaignAdd.email_field_name"
                             class="col-md-6 input_field">
                          <input type="text" placeholder="Enter your name">
                        </div>
                        <div class="input_field"
                             :class="{'col-md-6': getCampaignAdd.email_field_name, 'col-md-12': !getCampaignAdd.email_field_name}">
                          <input type="text" placeholder="Enter your email address">
                        </div>

                        <div class="box_btn">
                          <a class="btn"
                             :style="{'background-color': getCampaignAdd.button_background_color, 'color': getCampaignAdd.button_text_color}"
                             href="javascript:;">{{ getCampaignAdd.button_text }}</a>
                        </div>
                      </div>

                    </div>

                    <div v-if="computePowerBy() && !isWhiteLabelSetup()" class="box_poweredby"
                         :style="{'color': getCampaignAdd.text_color}">
                      <a :style="{'color': getCampaignAdd.text_color}" class="" href="https://replug.io/?utm_source=powered_by_replug"
                         target="_blank">Powered by
                        <span><strong :style="{'color': getCampaignAdd.text_color}">{{ getSiteDetails.agency_name }}</strong></span>
                      </a>
                    </div>

                  </div>
                  <div class="checkbox_option"
                       v-if="getCampaignAdd.type =='form' && getCampaignAdd.agreement.length > 0">
                    <div class="checkbox_option_inner">

                      <div class="small checkbox top_side"
                           v-for="agreement in getCampaignAdd.agreement">
                        <input :id="agreement.id" type="checkbox">
                        <label :for="agreement.id" v-html="agreement.text"></label>
                      </div>

                    </div>

                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>

        <!-- ----------------------EXIT INTENT TEMPLATE -------------- -->

        <div class="shoutout_exit_intent" v-if="getCampaignAdd.campaign_type.cta && ['bridge_cta', 'exit_intent'].includes(getCampaignAdd.cta_type)">
          <div
            :style="[exitIntentBackgroundImageCheck ? {'background': 'url(' + getCampaignAdd.background_image_exit_intent + ') no-repeat center', 'opacity': getCampaignAdd.exit_intent_opacity} : {'background-color': getCampaignAdd.background_color, 'opacity': getCampaignAdd.exit_intent_opacity}]"
            class="bg exit_intent_bg"></div>

          <button class="close_btn"
                  :style="{'box-shadow':'none' ,'border': 'none' ,'background-color': 'transparent', 'color': getCampaignAdd.text_color}">
            <i class="fas fa-times"></i>
          </button>

          <div class="_scroll">
            <div class="box_inner">
              <div class="img_block">
                <div class="img align_center">
                  <img width="40%" :src="brandAvatar()" alt="">
                </div>
                <p :style="{'color': getCampaignAdd.text_color + '!important'}" class="recommended_text">Recommended
                  size: (920 x 1080)</p>
              </div>
              <template v-if="getSaveLink.has_custom_message">
                <h2 :style="{'color': getCampaignAdd.text_color}">{{ getSaveLink.message_headline }}</h2>
                <p class="cta_message"
                   :style="{'color': getCampaignAdd.text_color}">{{ getSaveLink.message }}</p>
              </template>
              <template v-else>
                <h2 :style="{'color': getCampaignAdd.text_color}">{{ getCampaignAdd.message_headline }}</h2>
                <p class="cta_message"
                   :style="{'color': getCampaignAdd.text_color}">{{ getCampaignAdd.message }}</p>
              </template>

              <div v-if="getCampaignAdd.type == 'form'"
                   class=" inner fields_three fields_three_full">
                <input v-if="getCampaignAdd.email_field_name" class="item_input"
                       type="text" placeholder="Enter your name">
                <input class="item_input" type="email" placeholder="Enter your email address">

                <div class="checkbox_option"
                     v-if="getCampaignAdd.agreement.length > 0">
                  <div class="small checkbox top_side"
                       v-for="agreement in getCampaignAdd.agreement">
                    <input :id="agreement.id" type="checkbox">
                    <label :for="agreement.id" v-html="agreement.text"></label>
                  </div>
                </div>

                <a
                  :style="{'background-color': getCampaignAdd.button_background_color, 'color': getCampaignAdd.button_text_color}"
                  class="item_input btn blue">{{ getCampaignAdd.button_text }}</a>
              </div>
              <div v-if="getCampaignAdd.type == 'link'" class=" link_block">

                <!--:style="{'color': getCampaignAdd.button_text_color}"-->
                <a class="link_btn"
                   :style="{'color': getCampaignAdd.link_color}"
                   v-if="getCampaignAdd.type == 'link'"
                   :href="getCampaignAdd.button_url"
                   target="_blank">{{ getCampaignAdd.button_text }}</a>

              </div>

              <div v-if="getCampaignAdd.type == 'button'" class="btn_style">

                <a
                  :style="{'background-color': getCampaignAdd.button_background_color, 'color': getCampaignAdd.button_text_color}"
                  v-if="getCampaignAdd.type == 'button'"
                  :href="getCampaignAdd.button_url" class="btn "
                  target="_blank">{{ getCampaignAdd.button_text }}</a>

              </div>
              <div class="clearfix"></div>
              <div v-if="computePowerBy() && !isWhiteLabelSetup()" class="box_poweredby"><p :style="{'color': getCampaignAdd.text_color + '!important'}">Powered by <a href="https://replug.io/?utm_source=powered_by_replug"
                                                                                      target="_blank"><strong :style="{'color': getCampaignAdd.text_color}">{{
                  getSiteDetails.agency_name
                }}</strong></a>
              </p></div>

            </div>

          </div>

        </div>

        <!-- ----------------------POPUP CENTER TEMPLATE -------------- -->

        <div class="popupCenter" v-if="getCampaignAdd.cta_type == 'popup_center' && getCampaignAdd.type!='retargeting'
                && getCampaignAdd.type!='integration' && getCampaignAdd.type != 'no_cta'">

          <div class="bg"></div>

          <div class="_scroll">
            <div class="box_inner">
              <div class="box_inner_main"
                   :style="{'background-color': getCampaignAdd.background_color}">
                <button class="close_btn"
                        :style="{'background-color': 'transparent', 'color': getCampaignAdd.text_color}">
                  <i class="fas fa-times"></i>
                </button>
                <div class="content clear align_center">
                  <div class="img_block">
                    <div class="img align_center">
                      <!--                    <clip-loader class="v_center_loader"-->
                      <!--                                 v-if="getCampaignLoaders.campaign_image" :size="size"-->
                      <!--                                 :color="color"></clip-loader>-->
                      <!--                    <template v-else>-->
                      <!--                      <div class="icon_box">-->
                      <!--                        <button class="btn btn_upload">-->
                      <!--                          <input accept=".png, .jpg, .jpeg, .gif"-->
                      <!--                                 @change.prevent="uploadCampaignFormImage($event)"-->
                      <!--                                 type="file"-->
                      <!--                                 id="inputImage">-->
                      <!--                          <label for="inputImage">-->
                      <!--                            <i class="far fa-upload" style="color:#fff;"></i>-->
                      <!--                          </label>-->
                      <!--                        </button>-->

                      <!--                        <button class="btn btn_remove"-->
                      <!--                                @click.prevent="removeCampaignImage()">-->
                      <!--                          <i class="fa fa-times"></i>-->
                      <!--                        </button>-->
                      <!--                      </div>-->
                      <!--                    </template>-->
                      <img :src="brandAvatar()" alt="">
                    </div>
                    <p class="recommended_text">Recommended size: (920 x 1080)</p>
                  </div>

                  <div class=" text_block">

                    <template v-if="getSaveLink.has_custom_message">
                      <h2 :style="{'color': getCampaignAdd.text_color}">{{ getSaveLink.message_headline }}</h2>
                      <p class="cta_message"
                         :style="{'color': getCampaignAdd.text_color}">{{ getSaveLink.message }}</p>
                    </template>
                    <template v-else>
                      <h2 :style="{'color': getCampaignAdd.text_color}">{{ getCampaignAdd.message_headline }}</h2>
                      <p class="cta_message"
                         :style="{'color': getCampaignAdd.text_color}">{{ getCampaignAdd.message }}</p>
                    </template>

                  </div>
                </div>

                <div class="btn_block"
                     :style="{'background-color': getCampaignAdd.background_color}">
                  <div v-if="getCampaignAdd.type == 'form'"
                       class=" inner align_center fields_three">
                    <input v-if="getCampaignAdd.email_field_name"
                           class="item_input" type="text" placeholder="Enter your name">
                    <input class="item_input" type="email" placeholder="Enter your email address">
                    <button style="min-width: 30%;"
                            :style="{'background-color': getCampaignAdd.button_background_color, 'color': getCampaignAdd.button_text_color}"
                            class="item_input btn blue">
                      {{ getCampaignAdd.button_text }}
                    </button>
                  </div>

                  <div v-if="getCampaignAdd.type == 'link'"
                       class=" link_block">

                    <a class="link_btn"
                       :style="{'color': getCampaignAdd.link_color}"
                       v-if="getCampaignAdd.type == 'link'"
                       :href="getCampaignAdd.button_url"
                       target="_blank">{{ getCampaignAdd.button_text }}</a>
                  </div>

                  <div v-if="getCampaignAdd.type == 'button'"
                       class="btn_style">

                    <a
                      :style="{'background-color': getCampaignAdd.button_background_color, 'color': getCampaignAdd.button_text_color}"
                      v-if="getCampaignAdd.type == 'button'"
                      :href="getCampaignAdd.button_url" class="btn btn-sm"
                      target="_blank">{{ getCampaignAdd.button_text }}</a>

                  </div>

                </div>

                <div class="checkbox_option"
                     v-if="getCampaignAdd.type =='form' && getCampaignAdd.agreement.length > 0">
                  <div class="checkbox_option_inner">
                    <div class="small checkbox top_side"
                         v-for="agreement in getCampaignAdd.agreement">
                      <input :id="agreement.id" type="checkbox">
                      <label :for="agreement.id" v-html="agreement.text"></label>
                    </div>
                  </div>
                </div>
                <div v-if="computePowerBy() && !isWhiteLabelSetup()" :style="{'color': getCampaignAdd.text_color}" class="box_poweredby p-2"><p :style="{'color': getCampaignAdd.text_color + '!important'}">Powered by <a href="https://replug.io/?utm_source=powered_by_replug" target="_blank"><strong :style="{'color': getCampaignAdd.text_color}">{{
                    getSiteDetails.agency_name
                  }}</strong></a>
                </p></div>
              </div>
            </div>
          </div>

        </div>

        <!-- ----------------------POPUP BOTTOM SCROLL TEMPLATE -------------- -->

        <div class="popupBottom" v-if="getCampaignAdd.cta_type == 'scroll_box' && getCampaignAdd.type!='retargeting'
                && getCampaignAdd.type!='integration' && getCampaignAdd.type != 'no_cta'"
             :class="{'left_move': getCampaignAdd.position == 'left', 'right_move': getCampaignAdd.position == 'right'}">
          <div class="box_inner">

            <button class="close_btn"
                    :style="{'background-color': getCampaignAdd.background_color, 'color': getCampaignAdd.text_color}">
              <i class="fas fa-times"></i>
            </button>
            <div class="content"
                 :style="{'background-color': getCampaignAdd.background_color}">


              <div class="image_box">
                <img :src="showCampaignImage()">
              </div>

              <div class=" text_block mt-3">

                <template v-if="getSaveLink.has_custom_message">
                  <h2 :style="{'color': getCampaignAdd.text_color}">
                    {{ getSaveLink.message_headline }}</h2>
                  <p class="cta_message"
                     :style="{'color': getCampaignAdd.text_color}">{{ getSaveLink.message }}</p>
                </template>
                <template v-else>
                  <h2 :style="{'color': getCampaignAdd.text_color}">{{ getCampaignAdd.message_headline }}</h2>
                  <p class="cta_message"
                     :style="{'color': getCampaignAdd.text_color}">{{ getCampaignAdd.message }}</p>
                </template>

              </div>
              <div v-if="getCampaignAdd.type == 'form'" class=" basic_form">
                <div class="input_field" v-if="getCampaignAdd.email_field_name">
                  <input type="text" placeholder="Enter your name">
                </div>
                <div class="input_field">
                  <input type="email" placeholder="Enter your email address">
                </div>

                <div class="checkbox_option"
                     v-if="getCampaignAdd.agreement.length > 0">
                  <div class="checkbox_option_inner">
                    <div class="small checkbox top_side"
                         v-for="agreement in getCampaignAdd.agreement">
                      <input :id="agreement.id" type="checkbox">
                      <label :for="agreement.id" v-html="agreement.text"></label>
                    </div>
                  </div>
                </div>

                <div class="btn_block">
                  <button
                    :style="{'background-color': getCampaignAdd.button_background_color, 'color': getCampaignAdd.button_text_color}"
                    class="btn blue">{{ getCampaignAdd.button_text }}
                  </button>
                </div>
              </div>

              <div v-if="getCampaignAdd.type == 'link'" class=" link_block">

                <a class="link_btn"
                   :style="{'color': getCampaignAdd.link_color}"
                   v-if="getCampaignAdd.type == 'link'"
                   :href="getCampaignAdd.button_url"
                   target="_blank">{{ getCampaignAdd.button_text }}</a>

              </div>


              <a
                :style="{'background-color': getCampaignAdd.button_background_color, 'color': getCampaignAdd.button_text_color}"
                v-if="getCampaignAdd.type == 'button'"
                :href="getCampaignAdd.button_url" class="btn"
                target="_blank">{{ getCampaignAdd.button_text }}</a>


              <div v-if="computePowerBy() && !isWhiteLabelSetup()" :style="{'color': getCampaignAdd.text_color}" class="box_poweredby"><p :style="{'color': getCampaignAdd.text_color + '!important'}">Powered by <a href="https://replug.io/?utm_source=powered_by_replug"
                                                                                      target="_blank"><strong :style="{'color': getCampaignAdd.text_color}">{{
                  getSiteDetails.agency_name
                }}</strong></a>
              </p></div>

            </div>

          </div>

        </div>

        <!--&lt;!&ndash; &#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;REDIRECT TEMPLATE &#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; &ndash;&gt;-->
        <!--<div class="redirect_template"  v-if="getCampaignAdd.cta_type == 'redirect' && getCampaignAdd.type!='retargeting'-->
        <!--&& getCampaignAdd.type!='integration' && getCampaignAdd.type != 'no_cta'">-->

        <!--<div class="bg"></div>-->

        <!--<div class="_scroll">-->
        <!--<div class="box_inner"  >-->
        <!--<div class="box_inner_main">-->
        <!--<button class="close_btn">-->
        <!--<img src="/img/cancel.png" alt="">-->
        <!--</button>-->
        <!--asdasd-->

        <!--</div>-->

        <!--<div v-if="getCampaignAdd.branding_logo == 'true'" class="box_poweredby"><p>Powered by <a href="" target="_blank">Replug</a></p></div>-->

        <!--</div>-->
        <!--</div>-->

        <!--</div>-->

      </div>

      <div class="clearfix"></div>

    </div>

  </transition>
</template>

<script>

import {mapGetters, mapActions} from 'vuex'
import PreviewScreen from './PreviewScreen'
import WidgetPreview from './WidgetPreview'
import {campaignTypes} from '@/state/modules/mutation-types'
import {DARK_PURPLE_LOADER_COLOR} from '@/common/constants'

export default {
  'name': 'preview',
  components: {
    PreviewScreen,
    WidgetPreview
  },
  data: function () {
    return {
      toggleStatus: true,
      size: '14px',
      color: DARK_PURPLE_LOADER_COLOR,
      custom_script_test: ``,
    }
  },
  created () {
    $(document).ready(function () {
      // closing animation for Exit Intent
      $(document).on('click', '.shoutout_exit_intent .close_btn', function () {
        var boxInner = $('.shoutout_exit_intent .box_inner')
        var boxOuter = $('.shoutout_exit_intent .bg')
        boxInner.addClass('animated fadeOutUp')
        setTimeout(function () {
          boxOuter.addClass('animated fadeOut')
        }, 300)

        boxOuter.on('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function (event) {
          boxOuter.removeClass('animated fadeOut')
          boxInner.removeClass('animated fadeOutUp')
        })
      })

      // closing animation for Popup dialog
      $(document).on('click', '.popupCenter .close_btn', function () {
        var boxInner = $('.popupCenter .box_inner')
        var boxOuter = $('.popupCenter .bg')
        boxInner.addClass('animated fadeOutUp')
        setTimeout(function () {
          boxOuter.addClass('animated fadeOut')
        }, 300)

        boxOuter.on('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function (event) {
          boxOuter.removeClass('animated fadeOut')
          boxInner.removeClass('animated fadeOutUp')
        })
      })

      // closing animation for Scroll Box
      $(document).on('click', '.popupBottom.left_move .close_btn', function () {
        var boxOuter = $('.popupBottom')
        boxOuter.addClass('animated translateX')
        boxOuter.on('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function (event) {
          boxOuter.removeClass('animated translateX')
        })
      })

      $(document).on('click', '.popupBottom.right_move .close_btn', function () {
        var boxOuter = $('.popupBottom')
        boxOuter.addClass('animated translate_X')
        boxOuter.on('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function (event) {
          boxOuter.removeClass('animated translate_X')
        })
      })
    })
  },

  computed: {

    ...mapGetters([
      'getCampaignAdd',
      'getCampaigns',
      'getCampaignLoaders',
      'getLinks', 'getProfile', 'getSaveLink', 'getComponentCampaignList'

    ]),

    borderColor () {
      if (this.getCampaignAdd.type === 'link') {
        return this.getCampaignAdd.link_color
      } else {
        return this.getCampaignAdd.button_background_color
      }
    },

    exitIntentBackgroundImageCheck () {
      if (this.getCampaignAdd && this.getCampaignAdd.background_image_exit_intent) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {

    ...mapActions(['previewWidget',
      'uploadCampaignFormImage']),
    brandAvatar () {
      let brand_avatar = this.getCampaignAdd.image ? this.getCampaignAdd.image : (this.getSelectedBrandObj(this.getCampaignAdd.brand_id).avatar ? this.getSelectedBrandObj(this.getCampaignAdd.brand_id).avatar : '')
      if (brand_avatar) {
        return brand_avatar
      }
      const brandObj = this.getComponentCampaignList.find(item => this.getCampaignAdd.brand_id === item.brand_id)
      return brandObj && brandObj.brand && brandObj.brand.avatar ? brandObj.brand.avatar : '/assets/img/profile-default.png'
    },
    frameload (event, iframeName) {
      setTimeout(function () {
        let obj = document.getElementById(iframeName)
        console.debug(obj)
        //                    $("#test_iframe_html").html(obj.contentWindow.document.body.innerHTML);
        //                    document.getElementById('test_iframe_html').contentWindow.location.reload(true);

        obj.style.height = obj.contentWindow.document.body.scrollHeight + 'px'

        setTimeout(function () {
          obj.style.height = obj.contentWindow.document.body.scrollHeight + 'px'
        }, 2500)
      }, 200)
    },
    popupShow () {
      this.toggleStatus = true
    },
    removeCampaignImage () {
      this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_IMAGE, null)
      this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_RESIZE_IMAGE, null)
    },
    showCampaignImage () {
      return this.getCampaignAdd.image ? this.getCampaignAdd.image :
        this.backgroundImage(this.brandAvatar(), false)
    }
  },

  beforeDestroy () {
    var selector = document.getElementById('#shoutout_box_single')
    document.removeEventListener('animationend', selector)
  }

}
</script>

<style scoped lang="less">
.generic_site_preview .shoutout_box_wide .shoutout_box_inner .box_content .inner_content_inline .btn {
  width: 100px;
}

.default_image {
  border: 1px dashed #bcc1d4;
}

.recommended_text {
  font-size: 12px !important;
  margin-top: 10px !important;
  color: #1f216e !important;
}

.shoutout_exit_intent {
  background: transparent;

  .exit_intent_bg {
    background-size: cover !important;
  }
}

.shoutout_box_single, .single_box_bottomSmall {
  .box_footer {
    .image_box {
      padding: 0px !important;

      img {
        width: 65px !important;
        height: 65px !important;
        padding: 5px !important;
      }
    }
  }
}

.single_box_bottomSmall {
  .shoutout_box_inner {
    .top_heading {
      background: transparent;

      .close_btn {
        background: transparent;
      }
    }
  }
}

.text_center {
  text-align: center;
}

.shoutout_box_wide {
  .shoutout_box_inner {
    .box_content {
      .box_left {
        .image_box {
          img {
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
